(function($) {

//******************************************************************************
//* Breadcrumbs
//******************************************************************************
nd_breadcrumbs = {
    last_scroll_start_position: 0, // Last scroll start position
    last_scroll_start_position_timer: undefined,
    get_last_scroll_position: true,  
    breadcrumb_element: '.breadcrumbs.visible_on_scroll_up',

    init: function () {
        this._init_events();
    },

    _init_events: function () {
        $(window).bind('nd_scroll_thinned_out' , function () {
            if (window.innerWidth <= SCREEN_SIZE_XS_MAX) {
                nd_breadcrumbs._toggle_visibility_on_scroll();
            }
        });
    },

    // Show breadcrumb on scroll up
    _toggle_visibility_on_scroll: function () {
        var current_scroll_position = $('body').scrollTop();

        // Get scroll start position when scroll start
        if(nd_breadcrumbs.get_last_scroll_position) {
            nd_breadcrumbs.last_scroll_start_position = $('body').scrollTop();
            nd_breadcrumbs.get_last_scroll_position = false;
        }
       
        // Reset get_last_scroll_position when scroll is finished 
        clearTimeout(nd_breadcrumbs.last_scroll_start_position_timer);
        nd_breadcrumbs.last_scroll_start_position_timer = setTimeout(function() {
            nd_breadcrumbs.get_last_scroll_position = true;
        }, 200);

        // Hide breadcrumbs if scroll position is at top
        if (current_scroll_position === 0) {
            $(nd_breadcrumbs.breadcrumb_element).hide();
            $(nd_breadcrumbs.breadcrumb_element).removeClass('visible');
        }

        // Do nothing if scroll position value < 200
        if (current_scroll_position < 200) {
            return;
        }

        // Show breadcrumbs if scroll position > 200 
        $(nd_breadcrumbs.breadcrumb_element).show();

        // Hide if user scroll down
        if(current_scroll_position > nd_breadcrumbs.last_scroll_start_position + 50) {            
            $(nd_breadcrumbs.breadcrumb_element).removeClass('visible');
        }

        // Show if user scroll up
        if(current_scroll_position < nd_breadcrumbs.last_scroll_start_position - 50) {            
            $(nd_breadcrumbs.breadcrumb_element).addClass('visible');
        }

    }
};

})(jQuery); 