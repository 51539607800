(function($) {

/**
 * Topic circle
 */
nd_topic_circle = {
    init: function() {
        new WOW({
            boxClass: "wpb_content_element.topic_circle .desktop",
            offset: 500,
            live: false,
        }).init();
    },

};

})(jQuery);
