(function($) {

//******************************************************************************
//* Staff element flip on staff grid
//******************************************************************************
nd_staff_element_flip = {

    init: function () {

        $(".staff_element .staff_email, .staff_element .staff_phone a").click(function (event) {
        	event.stopPropagation();
        });

        $(".staff_element").click(function (event) {
			$(this).toggleClass('flipped');
        });
        
    }

};

})(jQuery); 
