(function($) {

email_handling = {
    init: function () {
    	this._init_clickevents();
    },

    _init_clickevents: function () {
        $('a.mail_link').click(function(event) {
            event.preventDefault();
            var location = $(this).attr('href');
            var w = window.open(location);
            w.document.title = "Email";
            setTimeout(function() { w.close(); }, 1000);
        });
    }
};

})(jQuery); 