(function($) {

nd_video_price_machine = {

    /**
     * Video prices determined by video style and duration
     */
    VIDEO_PRICES_MAP: {
        "whiteboard_animation": {
            "30": 2190,
            "60": 2490,
            "90": 2790,
            "120": 3290
        },
        "animation_2d_simple": {
            "30": 4290,
            "60": 7190,
            "90": 8590,
            "120": 9990
        },
        "animation_2d_detailed": {
            "30": 6490,
            "60": 9990,
            "90": 12990,
            "120": 15990
        },
        "animation_3d": {
            "30": 3590,
            "60": 4990,
            "90": 6990,
            "120": 7990
        }
    },

    video_type_button: "[data-section='video_type_element'] input",
    duration_button: "[data-section='duration_element'] input",
    video_style_button: "[data-section='video_style_element'] input",
    selection_details: "[data-section='selection_details']",
    
    init: function() {
        // this._set_initial_selection();
        this._update_duration_selection_label();
        this._update_video_style_prices();
        nd_price_machine.refresh_price();
        this._update_selection_details();
        this._init_event_handlers();
    },

    _set_initial_selection: function() {
        $(nd_price_machine.price_machine).find(this.video_type_button).first().attr('checked','checked');
        $(nd_price_machine.price_machine).find(this.duration_button).first().attr('checked','checked');
        $(nd_price_machine.price_machine).find(this.video_style_button).first().attr('checked','checked');
    },

    _init_event_handlers: function() {
        $(nd_price_machine.price_machine).find(this.video_type_button).change(function() {
            nd_price_machine.refresh_price();
            nd_video_price_machine._update_video_style_prices();
            nd_video_price_machine._update_selection_details();
        });

        $(nd_price_machine.price_machine).find(this.duration_button).change(function() {
            nd_price_machine.refresh_price();
            nd_video_price_machine._update_video_style_prices();
            nd_video_price_machine._update_selection_details();
            nd_video_price_machine._update_duration_selection_label();
        });

        $(nd_price_machine.price_machine).find(this.video_style_button).change(function() {
            nd_price_machine.refresh_price();
            nd_video_price_machine._update_selection_details();
        });
    },

    /**
     * Updates the selection label of duration selection element.
     */
    _update_duration_selection_label: function() {
        var $selected_duration_button = $(nd_price_machine.price_machine).find(this.duration_button).filter(":checked");
        var selected_duration_id = $selected_duration_button.attr("id");
        var selected_duration_value = $selected_duration_button.val();

        var $selection_label = $(nd_price_machine.price_machine)
            .find("[data-section='duration_element'] [data-field='selection_label']");

        var old_val = parseInt($selection_label.find("span").html());

        $selection_label
            .attr("data-selection", selected_duration_id)
            .find("span")
            .each(function() {
                var $this = $(this);
                jQuery({ Counter: old_val }).animate({ Counter: selected_duration_value }, {
                    duration: 1000,
                    easing: 'swing',
                    step: function () {
                        $this.text(Math.round(this.Counter));
                    }
                });
            });
    },

    /**
     * Updates the selection details in the bottom bar.
     */
    _update_selection_details: function() {
        // set selected video type
        var video_type = $(nd_price_machine.price_machine).find(this.video_type_button)
            .filter(":checked")
            .closest("[data-section='video_type_element']")
            .find("[data-field='title']")
            .html();

        $(nd_price_machine.price_machine).find(this.selection_details)
            .find("[data-field='video_type']")
            .html(video_type);

        // set selected duration
        var duration = $(nd_price_machine.price_machine).find(this.duration_button)
            .filter(":checked")
            .attr("data-description");

        $(nd_price_machine.price_machine).find(this.selection_details)
            .find("[data-field='duration']")
            .html(duration);

        // set selected video style
        var video_style = $(nd_price_machine.price_machine).find(this.video_style_button)
            .filter(":checked")
            .attr("data-description");

        $(nd_price_machine.price_machine).find(this.selection_details)
            .find("[data-field='video_style']")
            .html(video_style);
    },

    /**
     * Returns the computed total price.
     */
    calculate_total_price: function() {
        var selected_duration = $(nd_price_machine.price_machine).find(this.duration_button).filter(":checked").val();

        var selected_video_style = $(nd_price_machine.price_machine)
            .find("[data-section='video_style_element'] input:checked")
            .val();

        return this.VIDEO_PRICES_MAP[selected_video_style][selected_duration];
    },

    /**
     * Updates the total price for each video style element.
     */
    _update_video_style_prices: function() {
        var selected_duration = $(nd_price_machine.price_machine).find(this.duration_button).filter(":checked").val();

        $(nd_price_machine.price_machine).find("[data-section='video_style_element']").each(function() {
            $(this).find(".input_wrapper").each(function() {
                var video_style = $(this).find("input").val();
                var total_price = nd_video_price_machine.VIDEO_PRICES_MAP[video_style][selected_duration];
                $(this)
                    .find("[data-field='total_price'] .price_holder")
                    .html(nd_price_machine.get_formatted_price(total_price));
            });
        });
    }
};

})(jQuery);
