(function($) {

//******************************************************************************
//* Blog
//******************************************************************************
nd_blog = {

    init: function () {
        this._split_event_category_text();
    },

    // Split event category text
    _split_event_category_text: function() {
        $("[data-field='event_sub_category']").each(function () {
            var text = $(this).text().trim();
            var break_letters_count = $(this).attr('data-break-category-letters');

            $(this).html("<span>" + text.substr(0,break_letters_count) + "</span><span>" + text.substr(break_letters_count) + "</span>");
        });
    },
};

})(jQuery); 