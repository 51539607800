(function($) {
    

//******************************************************************************
//* Nd Newsletter
//******************************************************************************
nd_newsletter = {
    
    init: function () {

    	// Show succes message after successful newsletter form submit
    	$(document).bind('gform_confirmation_loaded', function(event, formId){
            $('.nd_newsletter').addClass('show_success_container');
        });
    }

};

})(jQuery);