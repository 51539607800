(function($) {

nd_news_teaser = {
    init: function() {
        $(".nd_news_teaser").each(function() {
            var $news_teaser = $(this);
            $(this).find(".nd_video [data-field='video_placeholder']").each(function() {
                var player = nd_youtube_iframe_api._get_player_from_element($(this));
    
                player.addEventListener("onStateChange", function(event) {
                    switch(event.target.getPlayerState()) {
                        case YT.PlayerState.BUFFERING:
                        case YT.PlayerState.PLAYING:
                            $news_teaser.addClass("video_playing");
                            break;
                        case YT.PlayerState.ENDED:
                        case YT.PlayerState.PAUSED:
                            $news_teaser.removeClass("video_playing");
                            break;
                    }
                });
            });
        });
    },
};

})(jQuery);
