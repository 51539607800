(function($) {

//******************************************************************************
//* Google analytics
//******************************************************************************
nd_analytics = {
    previous_offset: 0,
    scroll_delta: 0,
    first_load: true,

    EVENT_ACTION_CHANGE_OPTION: "change-option",

    init: function () {  
        nd_analytics._init_interface();
        nd_analytics._init_team();
        nd_analytics._init_contact();
        nd_analytics._init_cta();
        nd_analytics._init_projects();
        nd_analytics._init_blog();
        nd_analytics._init_testimonials();
        nd_analytics._init_video_price_machine();
    },

    // Initialize all interface related events
    _init_interface: function () {
        var category_name = 'interface';

        // Click on overlay scroll up button
        $('[data-anchor="scroll_up"]').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-overlay-scroll-up-button');
        });

        // Click on social media icon on footer and on mobile navigation
        $('[data-field="social_contact"] a').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-social-media-button', $(this).attr('data-social-media'));
        });

        // Click on breadcrumb link
        $('.breadcrumbs [property="item"]').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-on-breadcrumbs', $(this).attr('href'));
        });

        // Click on icon to open mobile menu
        var api = $('#mobile_nav').data('mmenu');

        api.bind('opened', function () { 
            nd_analytics._send_analytics_event(category_name, 'toggle-mobile-navigation', 'open');
        });

        api.bind('closed', function () { 
            nd_analytics._send_analytics_event(category_name, 'toggle-mobile-navigation', 'close');
        });

        // Click to toggle search bar visibility on header
        $('[data-field="search"]').click(function () {
            var label = "close";
            if ( nd_search.isSearchVisible() ) {
                label = "open";
            }            
            nd_analytics._send_analytics_event(category_name, 'toggle-search-field-by-header-search-icon', label);
            
        });

        // Send 'Scroll activity' event to analytics when user scroll more than current browser window height
        $(window).on('nd_scroll_thinned_out', function() {

            // Avoid different offset value when first page get loaded
            if (!nd_analytics.first_load) {
                var current_offset = $(window).scrollTop();

                nd_analytics.scroll_delta = nd_analytics.scroll_delta + Math.abs(current_offset - nd_analytics.previous_offset);
                nd_analytics.previous_offset = current_offset;

                // Send scroll event if scroll value is more than window height in any direction
                if (nd_analytics.scroll_delta > window.innerHeight) {
                    nd_analytics.scroll_delta = 0;

                    nd_analytics._send_analytics_event(
                                            category_name, 
                                            'scroll-activity', 
                                            window.location.href, 
                                            window.innerHeight
                                        );
                }
            }

            nd_analytics.first_load = false;
        });
    },

    _init_team: function () {
        var category_name = 'team';

        // Click to flip staff element
        $(".staff_element").click(function () {
            var name =  $(this).find('[data-section="front"] [data-field="title"]').text();
            var direction = "(flip to front)";

            if ($(this).hasClass('flipped')) {
                direction = "(flip to back)";
            }
            
            nd_analytics._send_analytics_event(category_name, 'flip-team-member', name + ' ' + direction);  
        });
    },

    _init_contact: function () {
        var category_name = 'contact';

        // Click on google map icon on contact page
        $("a.google_maps").click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-on-google-maps-link');
        });
    },

    _init_projects: function () {
        var category_name = 'projects';

        // Click on overlay project overview button
        $('[data-anchor="project_overview"]').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-overlay-project-overview-button');
        });

        // Click on project grid filter
        $('.nd-projects-teaser-navigation-skin .esg-filterbutton').click(function () {
            var filter_category = $(this).attr('data-filter').replace('filter-','');
            nd_analytics._send_analytics_event(category_name, 'click-project-filter', filter_category);
        });

        // Click on read more button on project detail page
        $('.single-project .max_height_row .expand_row_button').click(function () {
            var event_label = 'close';

            if($(this).closest('.vc_row').hasClass('opened')) {
                event_label = 'open';
            }

            nd_analytics._send_analytics_event(category_name, 'project-read-more-section', event_label);
        });

        // Slide related projects carousel
        $('.projects_carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            nd_analytics._send_analytics_event(category_name, 'slide-related-project-slider');            
        });

        // Click on project in projects grid
        $('.nd-projects-teaser-navigation-skin .nd_projects_teaser_wrapper').click(function () {
            nd_analytics._send_analytics_event(category_name, 'open-project-from-project-grid', '', $(this).closest('li').index() + 1);
        });

    },

    _init_blog: function () {
        var category_name = 'blog';

        // Click on blog grid filter
        $('.nd-blog-articles-teaser-navigation-skin .esg-filterbutton').click(function (e) {
            var filter_category = $(this).attr('data-filter').replace('filter-','');
            nd_analytics._send_analytics_event(category_name, 'click-blog-filter', filter_category);
        });

        // Slide related articles carousel
        $('.related_articles_carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            nd_analytics._send_analytics_event(category_name, 'slide-related-blog-article-slider');            
        });

        // Click on blog grid load more button
        $('.nd-blog-articles-teaser-navigation-skin .esg-loadmore').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-blog-overview-load-more-button');
        });

        // Click on blog article in blog grid
        $('.nd-blog-articles-teaser-navigation-skin .nd_blog_articles_teaser_wrapper').click(function () {
            nd_analytics._send_analytics_event(category_name, 'open-blog-article', '', $(this).closest('li').index() + 1);
        });
    },

    _init_testimonials: function () {
        var category_name = 'testimonials';

        // Slide testimonial carousel
        $('.testimonial_carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            nd_analytics._send_analytics_event(category_name, 'slide-testimonial-slider');            
        });

        // Click on testimonial grid load more button
        $('.load_more_testimonial').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-testimonial-load-more-button');
        });

        // Click on read more button on testimonial
        $('.expand_testimonial_button').click(function () {
            var event_label = '';

            if ($(this).hasClass('opened')) {
                event_label = 'open';
            } else {
                event_label = 'close';
            }

            nd_analytics._send_analytics_event(category_name, 'testimonial-read-more-section', event_label);
        });
    },

    _init_cta: function () {
        var category_name = 'cta';

        // Click on cta phone number
        $('.contact_banner [data-field="phone_number"]').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-cta-phone-number', $(this).find('a').text());
        });

        // Click on cta email address
        $('.contact_banner .staff_email').click(function () {
            nd_analytics._send_analytics_event(category_name, 'click-cta-mail-address', $(this).attr('data-email-slug'));
        });
    },

    _init_video_price_machine: function() {
        var category_name = "video-price-machine";
        var $video_price_machine = $('[data-section="video_price_machine"]');

        // video type
        $video_price_machine.find('[data-section="video_type_checkbox"] label').click(function() {
            var video_type = $(this).siblings("input").attr("data-description");
            nd_analytics._send_analytics_event(category_name, nd_analytics.EVENT_ACTION_CHANGE_OPTION, "Video Type - " + video_type);
        });

        // duration
        $video_price_machine.find('[data-section="duration_element"] label').click(function() {
            var duration = $(this).closest('[data-section="duration_selection"]').find("input").attr("data-description");
            nd_analytics._send_analytics_event(category_name, nd_analytics.EVENT_ACTION_CHANGE_OPTION, "Duration - " + duration);
        });

        // video style
        $video_price_machine.find('[data-section="video_style_element"] label').click(function() {
            var video_style = $(this).siblings("input").attr("data-description");
            nd_analytics._send_analytics_event(category_name, nd_analytics.EVENT_ACTION_CHANGE_OPTION, "Video Style - " + video_style);
        });
    },

    // Send google analytics options
    _send_analytics_event: function (category, action, label, value) {
        var options = {};

        if(category !== undefined && category.length > 0) {
            options.event_category = category; 
        }
        if(label !== undefined && label.length > 0) {
            options.event_label = label; 
        }
        if(value !== undefined && value !== '') {
            options.value = value; 
        }

        gtag('event', action, options);
    }
};

})(jQuery); 