(function($) {

/**
 * Process circle
 */
nd_process_circle = {
    process_circle_desktop_elem: ".wpb_content_element.process_circle .desktop",

    $num_processes: 0,

    init: function() {
        this.$num_processes = nd_process_circle._get_num_processes();
        this._init_event_handlers();
        this._set_selected_process(1);
    },

    _init_event_handlers: function() {
        $(this.process_circle_desktop_elem).find(".step_button_prev").click(function() {
            var selected_process = nd_process_circle._get_selected_process();
            if (selected_process > 1) {
                nd_process_circle._set_selected_process(selected_process - 1);
            }
        });

        $(this.process_circle_desktop_elem).find(".step_button_next").click(function() {
            var selected_process = nd_process_circle._get_selected_process();
            if (selected_process < nd_process_circle.$num_processes) {
                nd_process_circle._set_selected_process(selected_process + 1);
            }
        });

        $(this.process_circle_desktop_elem).find("[data-field='process_heading']").click(function() {
            var process_id = nd_process_circle._get_process_id($(this));
            nd_process_circle._set_selected_process(process_id);
        });
    },

    _get_selected_process: function() {
        return parseInt($(this.process_circle_desktop_elem).attr("data-selected-process"));
    },

    _set_selected_process: function(process) {
        $(this.process_circle_desktop_elem).attr("data-selected-process", process);
    },

    _get_num_processes: function() {
        return parseInt($(this.process_circle_desktop_elem).attr("data-num-processes"));
    },

    _get_process_id: function($elem) {
        return parseInt($elem.attr("data-process-id"));
    },
};

})(jQuery);
