(function($) {

//******************************************************************************
//* Offer teaser
//******************************************************************************

nd_offer_teaser = {

    inquiry_form_pre_filled_message_cookie_name: "inquiry_form_pre_filled_message",

    init: function () {
        this._init_event_handling();
    },

    _init_event_handling: function() {
        // set a cookie containing the pre-filled message when clicking on
        // the send_inquiry_button, so the message can be accessed in the
        // inquiry form context for pre-filling the message field.
        $(".wpb_content_element.offer_teaser [data-field='send_inquiry_button']").click(function(e) {
            e.preventDefault();

            // query the pre-filled message from the hidden field
            var pre_filled_message = $(this)
                .closest(".wpb_content_element.offer_teaser")
                .find(".pre_filled_message")
                .text()
                .trim();

            // set a cookie containing the pre-filled message
            document.cookie = nd_offer_teaser.inquiry_form_pre_filled_message_cookie_name + "=" +
                encodeURIComponent(pre_filled_message) + ";path=/";

            // open the inquiry form page in a new tab
            // TODO: check if this works properly in all browsers
            var url = $(this).attr("href");
            window.open(url, "_blank");
        });
    },
};

})(jQuery);
