(function($) {

//******************************************************************************
//* Fixed vc row
//******************************************************************************
nd_fixed_row = {
    container_height: 0,

    init: function () {

        // Refresh when the window is loaded
        $( window ).load(function() {
            nd_fixed_row.refresh();
        });
        this._set_max_height();
        this._toggle_class_on_button_click();

        $(window).on('nd_resize_ready nd_vc_row_ready', function() {
            nd_fixed_row.refresh();
            nd_fixed_row._set_max_height();
        });
                
    },

    refresh: function () {

        if (window.innerWidth > BREAKPOINT_NAV_MOBILE_MAX) {
            $(".fixed_row").each(function () {
                
                var container_height = $(this).height();
                $('.fixed_row_spacer').height(container_height);
                $('.fixed_row_container').addClass("spacer_height_set");
            });
        }
    },

    // Calculate height
    _calculate_container_height: function ($row) {
        var column_height = 0;
        
        $row.find('.wpb_column').each(function() {
            if (window.innerWidth > SCREEN_SIZE_XS_MAX) {
                if(column_height < $(this).height()) {
                    column_height = $(this).height();
                }
            } else {
                column_height = column_height + $(this).height();
            }
        });
        return column_height + 100;
    },

    // Set max height from custom data attribute
    _set_max_height: function () {
        
        $(".vc_row").each(function () {

            if( $(this).hasClass('max_height_row') ) {
                if( ! $(this).hasClass('opened') ){
                    if (window.innerWidth > SCREEN_SIZE_XS_MAX) {
                        if ($(this).attr('data-max-height-desktop')) {
                            $(this).css('maxHeight', parseInt($(this).attr('data-max-height-desktop')));
                        } else {
                            $(this).css('maxHeight', '');
                        }
                    } else {
                        if ($(this).attr('data-max-height-mobile')) {
                            $(this).css('maxHeight', parseInt($(this).attr('data-max-height-mobile')));
                        }else {
                            $(this).css('maxHeight', '');
                        }
                    }
                } else {
                    var container_height = nd_fixed_row._calculate_container_height($(this));
                    $(this).css('maxHeight', container_height);
                }
            }

        });
    },

    // Toggle 'opened' class in button click and set a default max height value
    _toggle_class_on_button_click: function () {
        $('.expand_row_button').click(function(event){
            event.preventDefault();
            $(this).closest('.vc_row').toggleClass('opened');
            if($(this).closest('.vc_row').hasClass('opened')) {
                var container_height = nd_fixed_row._calculate_container_height($(this).closest('.vc_row'));
                $(this).closest('.vc_row').css('maxHeight', container_height);
            } else {
                nd_fixed_row._set_max_height();
            }
        });
    }

};

})(jQuery); 