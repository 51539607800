(function($) {

/**
 * Embedded video element
 */
nd_embedded_video = {

    VIDEO_RESPONSIVE_WIDTHS: [
        {
            "width": 480,
            "data_source": "data-source-mobile"
        },
        {
            "width": 960,
            "data_source": "data-source-tablet"
        },
        {
            "width": 1920,
            "data_source": "data-source-desktop"
        }
    ],

    init: function() {

        nd_embedded_video._update_video_source(false);
        nd_embedded_video._init_wow();

        // On resize
        $(window).on('nd_resize_ready nd_vc_render_ready', function() {
            nd_embedded_video._update_video_source(true);
        });

        this._init_event_handling();
    },

    _init_wow: function () {
        // Autoplay on scroll
        new WOW({
            boxClass: "wow_video",
            offset: 50,
            live: true,
            callback: function(video) {
                if (video.readyState === 4) {
                    nd_embedded_video._init_video($(video));
                }
            }
        }).init();

        // Preload on scroll
        new WOW({
            boxClass: "wow_lazy_preload",
            animateClass: "lazy_preloaded",
            offset: -300,
            live: true,
            callback: function(video) {
                video.load();
            }
        }).init();
    },

    _init_event_handling: function() {
        $(".wpb_content_element.embedded_video").each(function() {
            var $video = $(this).find("video");
            var video = $video.get(0);
            var $play_button = $(this).find(".play_button");
            var $poster_image = $(this).find(".poster_image");
            var $loading_pane = $(this).find(".loading_pane");

            if (!$(this).hasClass("disable_controls")) {
                $(this).find(".overlay, [data-field='label'], .phone_overlay").click(function() {
                    if (video.paused) {
                        var playPromise = video.play();
                        // TODO: check if we need this
                        if (playPromise !== undefined) {
                            playPromise.then(function() {
                            }).catch(function(error) {
                            });
                        }
                    } else {
                        video.pause();
                    }
                });
            }

            $video.on("play", function() {
                // pause all other videos in this play group
                var play_group = $video.attr("data-play-group");
                if (play_group) {
                    $(".wpb_content_element.embedded_video video").not(video).each(function () {
                        if ($(this).attr("data-play-group") === play_group) {
                            $(this).get(0).pause();
                            $(this).closest(".wpb_content_element.embedded_video")
                                .find(".play_button")
                                .show();
                        }
                    });
                }

                $play_button.hide();
                $poster_image.hide();
            });

            $video.on("pause", function() {
                $play_button.show();
                $poster_image.show();
            });

            $video.on("ended", function() {
                $play_button.show();
                $poster_image.show();
            });

            $video.on("loadeddata", function() {
                $loading_pane.hide();
                nd_embedded_video._init_video($(this));
            });
        });
    },

    _update_video_source: function(load_video) {
        $(".wpb_content_element.embedded_video [data-section='video_wrapper']").each(function() {
            // Get correct video file size for current video element size
            var element_width = $(this).width();
            var data_source = "data-source-desktop";

            for (var i = 0; i < nd_embedded_video.VIDEO_RESPONSIVE_WIDTHS.length; i++) {
                var entry = nd_embedded_video.VIDEO_RESPONSIVE_WIDTHS[i];

                if (element_width <= entry.width) {
                    data_source = entry.data_source;
                    break;
                }
            }

            // Get sources
            var $source = $(this).find("video source");
            var old_src = $source.attr("src");
            var new_src = $source.attr(data_source) + "#t=0.1";

            // Update source if it differs from old source
            if (old_src !== new_src) {

                $source.attr("src", new_src);

                if (load_video) {
                    var $video = $(this).find("video");
                    if ($video.hasClass("lazy_preloaded")) {
                        $video.get(0).load();
                    }
                }
            }
        });
    },    

    _init_video: function($video) {
        if ($video.is("video")) {

            if ($video.hasClass("on_scroll") && $video.hasClass("animated")) {
                var playPromise = $video.get(0).play();
                if (playPromise !== undefined) {
                    playPromise.then(function() {
                      // Automatic playback started!
                      $video.closest(".wpb_content_element.embedded_video")
                        .find(".play_button")
                        .hide();
                    }).catch(function(error) {
                      // Automatic playback failed.
                    });
                  }
            } else if (!$video.hasClass("on_scroll")) {
                $video.closest(".wpb_content_element.embedded_video")
                    .find(".play_button")
                    .show();
            }
        }
    },
};

})(jQuery);
