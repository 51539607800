(function($) {

//******************************************************************************
//* Grid element (Project/Blog)
//******************************************************************************


nd_grid = {

    init: function () {
        this._init_isotope();
        this._init_interface();
    },

    _init_isotope: function () {
        var itemsPerPage = 8;
        if ($(window).width() > SCREEN_SIZE_MD_MAX) {
            itemsPerPage = 9;
        }

        $(".grid_wrapper").nd_filtered_isotope({
            itemsPerPage: itemsPerPage,
            filtersInUrl: ["categories"],
            onArrangeComplete: function () {
                nd_grid._refreshLoadMoreButton($(this));
            },
            onFiltersFromUrlSet: function () {
                nd_grid._refreshFilterButtonActiveness($(this));
            },
            customMethod: function() {
                nd_same_height_groups.refresh();
            }
        });
    },

    _init_interface: function () {
        // Filter click
        $('.grid_filter .filter_button').click(function (event) {
            var filtered_isotope = nd_grid._getFilteredIsotopeInstance($(this));
            var filter = $(this).attr("data-filter");
            
            if ( filter === "all" ) {
                filtered_isotope.clearFilter(["categories"]);
            }
            else {
                filtered_isotope.setFilter({
                    categories: [filter]
                });                
            } 

            // Refresh active class of filter button
            nd_grid._refreshFilterButtonActiveness($(this));          
        }); 

        // Show more
        $(".show_more_button").click(function (e) {
            var filtered_isotope = nd_grid._getFilteredIsotopeInstance($(this));
            filtered_isotope.loadMore();
            e.preventDefault();
        });
    },

    _refreshLoadMoreButton: function ($cur_elem) {
        var filtered_isotope = nd_grid._getFilteredIsotopeInstance($cur_elem); 

        if (filtered_isotope.isMoreItemsAvailable()) {
            this._getGridMainElement($cur_elem).find(".show_more_button").show();
        }
        else {
            this._getGridMainElement($cur_elem).find(".show_more_button").hide();    
        }
    },

    _refreshFilterButtonActiveness: function ($cur_elem) {
        var filtered_isotope = nd_grid._getFilteredIsotopeInstance($cur_elem);
        var cur_category_filter = filtered_isotope.getFilter().categories; // Get current filter
        var $grid =  this._getGridMainElement($cur_elem); // Get Grid element
        $grid.find(".filter_button").removeClass("active");

        // Set active state of filter 1
        if (cur_category_filter !== undefined) {
            // Set current filter button active
            var $active = $grid.find(".filter_button[data-filter='" + cur_category_filter[0] + "']");
            if ($active.length) {
                $active.addClass("active");
                $('.grid_filter .dropdown-toggle .selected_category').text($active.first().text());              
            }

        }
        else {
            // Set "All" button active
            $grid.find(".filter_button[data-filter='all']").addClass("active");
        }
   
    },

    _getGridMainElement: function ($cur_elem) {
        return $cur_elem.parents(".grid_element");
    },

    _getFilteredIsotopeInstance: function ($cur_elem) {
        return this._getGridMainElement($cur_elem).find(".grid_wrapper").nd_filtered_isotope("getInstance");
    }
    
};


})(jQuery);
