(function($) {

    //******************************************************************************
    //* Searchbar toggle
    //******************************************************************************
    nd_search = {
        init: function () {
            $('[data-field="search"]').on( 'click', function (event) {
                if ( nd_search.isSearchVisible() ) {
                    nd_search.hideSearch();
                } else {
                    nd_search.showSearch();             
                }
                event.stopPropagation();
            });

            $("body").click(function (event) {
                if (!$(event.target).closest('#search_bar').length) {
                    nd_search.hideSearch();
                }
            });
        },

        isSearchVisible: function () {
            if ($('#search_bar').hasClass('visible')) { return true; }
            else { return false; }
        },

        showSearch: function () {
            nd_nav_desktop._hide_overlay();
            $('#search_bar').addClass('visible');
            $('#search_bar input[type="search"]').focus();
        },

        hideSearch: function () {
            $('#search_bar').removeClass('visible');             
        },
    };

})(jQuery); 