

(function($) {

//******************************************************************************
//* References
//******************************************************************************
nd_references = {

    init: function () {
        $(".reference_grid").on('lazyloaded', function(e){
    		nd_same_height_groups.refresh();
		});
    }

};

})(jQuery);