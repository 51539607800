(function($) {

//******************************************************************************
//* Testimonial
//******************************************************************************
nd_testimonial = {
    allowed_visible_testimonial: 2,

    init: function () {
        this._init_carousel();
        this._init_list();
        this._init_text_collapse();
    },

    _init_carousel: function () {
        $(document).ready(function(){

            $('.testimonial_carousel').each(function() {
                var slick_options = {
                    speed: 1000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                };

                $(this).slick(slick_options);
            });

        });
    },


    _init_list: function () {
        $('.testimonial_list').each(function() {
            nd_testimonial._show_next_two_list_items($(this));
            nd_testimonial._refresh_load_more_button_visibility($(this));
        });

        $('.load_more_testimonial').click(function (event) {
            $list = $(event.target).closest('.testimonial_list');
            
            nd_testimonial._show_next_two_list_items($list);
            nd_testimonial._refresh_load_more_button_visibility($list);
            nd_testimonial._refresh_expand_button_and_overlay_visibility($list);
        });
    },

    _init_text_collapse: function () {
        $('.testimonial_list').each(function() {
            nd_testimonial._refresh_expand_button_and_overlay_visibility($(this));
        });    

        // Calculate and replace all opened testimonial text content height with new height on resize and refresh visiblity of load more button
        $(window).on('nd_resize_ready', function() {
            
            $('.testimonial_list').each(function() {
                nd_testimonial._refresh_load_more_button_visibility($(this));
                nd_testimonial._refresh_expand_button_and_overlay_visibility($(this));

                $(this).find('.testimonial_element').each(function() {
                    nd_testimonial._toggle_text_collapse($(this).find('[data-field="text_content"]'));
                });
            });
        });

        $('.expand_testimonial_button').click(function(event){
            event.preventDefault();
            $(this).toggleClass('opened');
            $(this).prev().toggleClass('opened');
            nd_testimonial._toggle_text_collapse($(this).prev());
        });
    },

    // Show next 2 hidden element in testimonial list 
    _show_next_two_list_items: function ($list) {
        var visible_item = 0;

        $list.find('.testimonial_element').each(function() {

            if (visible_item < nd_testimonial.allowed_visible_testimonial ) {
                if (!$(this).is(":visible")) {
                    $(this).slideDown();
                    visible_item++;
                }
            }

        });
    },

    // Hide expand testimonial button and overlay if the height of the element is less then 250px
    _refresh_expand_button_and_overlay_visibility: function ($list) {

        $list.find('.testimonial_element').each(function() {
            var orginal_height = $(this).find('.text').height();

            if (orginal_height < 250) {
                $(this).find('[data-field="text_content"]').attr('data-should-be-cutted', false);
                $(this).find('.expand_testimonial_button').hide();
                $(this).find('[data-field="fadeout_overlay"]').hide();
            } else {
                $(this).find('[data-field="text_content"]').attr('data-should-be-cutted', true);
                $(this).find('.expand_testimonial_button').show();
                $(this).find('[data-field="fadeout_overlay"]').show();
            }
        });

    },

    // Hide load more button if there is no hidden element
    _refresh_load_more_button_visibility: function ($list) {
        var one_invisible_found = false;

        $list.find('.testimonial_element').each(function() {
            if (!$(this).is(":visible")) {
                one_invisible_found = true;
            }
        });

        if(!one_invisible_found) {
            $list.find('.load_more_testimonial').hide();
        }
    },

    // Toggle height of text content
    _toggle_text_collapse: function ($element) {
        if ($element.hasClass('opened')) {
            var orginal_height = $element.find('.text').height();

            $element.css('maxHeight', parseInt(orginal_height));
        } else {
            $element.css('maxHeight', '');
        }
    },

};

})(jQuery);