(function($) {

//******************************************************************************
//* Moving image
//******************************************************************************
nd_moving_image = {
    scroll_event_counter: 0,

    init: function () {
        
        // Apply position
        $(window).on(
            'nd_scroll_thinned_out nd_resize_ready nd_vc_render_ready nd_header_image_loaded',
            function() {
                nd_moving_image.refresh();
            }
        );

        // Init
        $( window ).load(function() {
            nd_moving_image.refresh();
        });
    },

    refresh: function () {
        this._apply_scroll_position();
    },

    _apply_scroll_position: function () {
        // Reset on mobile
        if (window.innerWidth < 768) {
            $(".moving_image[data-position='move_on_scroll'] .inner").css('transform', '');
            return;
        }

        $(".moving_image[data-position='move_on_scroll']").each(function () {
            // Overflow needs to be visible for parent row
            $(this).parents(".vc_row").css("overflow", "visible");

            // Save current element size to variables
            var cur_height_px = $(this).find(".inner").height();
            var cur_width_px = $(this).find(".inner").width();

            // Calculate the distance to the screen center
            var distance_to_screen_center_px = ( this.getBoundingClientRect().top + (cur_height_px / 2) ) - (window.innerHeight / 2);
            
            // Calculate offset
            var offset_x_percent = parseInt( $(this).attr("data-scroll-offset-x") ) || 0;
            var offset_y_percent = parseInt( $(this).attr("data-scroll-offset-y") ) || 0;
            var offset_x_px = (offset_x_percent / 100) * cur_width_px;
            var offset_y_px = (offset_y_percent / 100) * cur_height_px;

            // Calculate speed ratio (50 is default value, so 50 is ratio = 1)
            var speed_ratio = ( parseInt( $(this).attr("data-scroll-speed") ) || 0 ) / 50;

            // Calculate y shift
            var shift_y_px = (distance_to_screen_center_px * speed_ratio) + offset_y_px;

            // Apply CSS
            $(this).children(".inner").css('transform', 'translateY(' + shift_y_px + 'px) translateX(' + offset_x_px + 'px)');
        });
    },
};

})(jQuery); 