(function($) {

nd_discount_sticky_note = {
    COOKIE_NAME: 'hide_discount_sticky_note',

    init: function () {
        this._init_events();     

        if (nd_discount_sticky_note._is_sticky_note_visible()) {
            nd_discount_sticky_note._set_status('opened');
        } else {
            nd_discount_sticky_note._set_status('closed');
        }

        setTimeout(function() {
            $(".sticky_discount_note").addClass('init');
            $(".sticky_discount_note_mobile").addClass('init');
        }, 2000);  
    },

    _init_events: function () {
        // Click on close button
        $(".sticky_discount_note .close_button").click(function (event) {
            nd_discount_sticky_note._set_status('closed');
            nd_discount_sticky_note._set_cookie();
        });

        $(".sticky_discount_note .left_triangle, .sticky_discount_note .inner.discount_amount").click(function () {
            nd_discount_sticky_note._set_status('opened');            
            nd_discount_sticky_note._remove_cookie();
        });

        // bottom bar positioning on scroll
        $(window).on('nd_scroll_thinned_out', function() {      
            var $sticky_discount_note_mobile_anchor_offset = $(".sticky_discount_note_mobile_anchor").offset();

            if (typeof $sticky_discount_note_mobile_anchor_offset !== 'undefined') {
                var offset_top = $sticky_discount_note_mobile_anchor_offset.top;
                var scroll_bottom = $(window).scrollTop() + $(window).height();
                var bottom_bar_height = $(".sticky_discount_note_mobile").outerHeight();

                if(scroll_bottom >= offset_top + bottom_bar_height) {
                    $(".sticky_discount_note_mobile").addClass("in_place");
                } else {
                    $(".sticky_discount_note_mobile").removeClass("in_place");
                }
            }
        });        
    },

    _set_status: function (status) {
        $('.sticky_discount_note').attr('data-status', status);
    },

    _set_cookie: function () {
        var date = new Date();
        date.setTime(date.getTime() + (60 * 60 * 1000)); // Cookie expires in 1 hour

        document.cookie = nd_discount_sticky_note.COOKIE_NAME + "=True; expires=" + date.toUTCString() + ";path=/";
    },

    _remove_cookie: function () {
        document.cookie = nd_discount_sticky_note.COOKIE_NAME + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },

    // Show sticky note if cookie is not set
    _is_sticky_note_visible: function () {
        var re_exp = new RegExp(nd_discount_sticky_note.COOKIE_NAME + "=([^;]+)");
        var value = re_exp.exec(document.cookie);
        
        // Return true if cookie is not found
        return (value != null) ? false : true;
    }
  
};

})(jQuery); 