(function($) {

//******************************************************************************
//* Recent articles carousel
//******************************************************************************
nd_related_articles_carousel = {

    init: function () {

        $(document).ready(function(){
            $('.related_articles_carousel').slick({
                speed: 1000,
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: true,
                responsive: [
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
    }

};

})(jQuery);