(function($) {

//******************************************************************************
//* Navigation desktop
//******************************************************************************
nd_nav_desktop = {
    scroll_top_start: undefined,
    
    init: function () {
        this._init_events();
        this._refresh_overlay_height();
        this._mark_menu_item_as_active();
    },

    _init_events: function () {
        $(window).on('nd_scroll_thinned_out', function() {
            nd_nav_desktop._refreshNavSize();

            if (nd_nav_desktop.scroll_top_start === undefined) {
                nd_nav_desktop.scroll_top_start = $(document).scrollTop();
            }

            if (Math.abs($(document).scrollTop() - nd_nav_desktop.scroll_top_start) > 100) {
                nd_nav_desktop._hide_overlay();
            }
        });

        $(window).on('nd_scroll_ready', function() {
            nd_nav_desktop.scroll_top_start = undefined;
        });

        $("li[data-level='0'][data-has-sub='true']").click(function (event) {
            // Click should not be propagated to body
            event.preventDefault();
            event.stopPropagation();

            // Get status and id of clicked element
            var cur = $(this);
            var is_selected = cur.hasClass("selected");
            var item_id = cur.attr("data-id");

            // Reset current status
            $("li[data-level='0']").removeClass("selected");
            $("[data-level='1'] .element").removeClass("selected");
            $("[data-level='1']").removeClass("visible");

            if (!is_selected) {   
                cur.addClass("selected");
                $("[data-level='1'][data-parent-id='" + item_id.toString() + "']").addClass("visible");
            }
            nd_search.hideSearch();
            nd_nav_desktop._refresh_overlay_visibility();
        });
        
         // Hide overlay on body click
        $("body").click(function () {
            nd_nav_desktop._hide_overlay();
        });

        $(window).on('nd_resize_ready', function() {
            nd_nav_desktop._hide_overlay();
            
            if ($(this).width() <= SCREEN_SIZE_MD_MAX) {
                $("[data-section='nav_overlay']").hide();
            } else {
                $("[data-section='nav_overlay']").show();
                nd_same_height_groups.refresh();
                nd_nav_desktop._refresh_overlay_height();
            }
        });
    },

    _refreshNavSize: function () {
        var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        
        if ( bodyScrollTop > 34) {
            $('nav.navbar').addClass('scrolled');
            $('body').addClass('page_scroll');
        }
        else {
            $('nav.navbar').removeClass('scrolled');
            $('body').removeClass('page_scroll');
        }
    },

    _refresh_overlay_visibility: function () {
        if ($("li[data-level='0'].selected").length > 0) {
            $("[data-section='nav_overlay']").addClass("visible");
            $("#desktop_nav > ul").addClass('has_opened_sub');
        }
        else {
            $("[data-section='nav_overlay']").removeClass("visible");
            $("#desktop_nav > ul").removeClass('has_opened_sub');
        }
    },

    _get_max_overlay_height: function () {

        // Calculate max height of current group
        max_height = 0;
        $("[data-section='nav_overlay'] .elements_wrapper").each(function () {
            if ($(this).outerHeight() > max_height) {
                max_height = $(this).outerHeight();
            }
        });

        return max_height;
    },

    _refresh_overlay_height: function () {
        var overlay_height = nd_nav_desktop._get_max_overlay_height();
        $("[data-section='nav_overlay']")
            .css("height", overlay_height)
            .css("top", "calc(-" + overlay_height + "px - 50vh)");
    },

    _hide_overlay: function () {
        $("li[data-level='0'].selected").removeClass("selected");
        nd_nav_desktop._refresh_overlay_visibility();
    },

    // Activate menu item on detail pages of custom post types
    _mark_menu_item_as_active: function() {
        if (php_to_js.nd_detail_page_post_type !== undefined) {
            var current_post_type = php_to_js.nd_detail_page_post_type;

            if (php_to_js.menu_item_to_activate.hasOwnProperty(current_post_type)) {
                $('.'+php_to_js.menu_item_to_activate[current_post_type]).addClass('current-menu-parent');
            }
        }
    }
};



//******************************************************************************
//* Navigation mobile
//******************************************************************************
nd_nav_mobile = {
    
    init: function () {     
        $("#mobile_nav").mmenu(
            { 
                extensions: ["pageshadow", "positioning", "fixedElements", "fullscreen"],
                offCanvas: { 
                    pageSelector: "#main_wrapper",
                    position: "right",
                    zposition : "front"
                }
            }
        );

        this._init_events();
    },

    _init_events: function () {
        // Change hamburger button status
        var api = $('#mobile_nav').data('mmenu');

        api.bind('opened', function () { 
            $("button.hamburger").addClass("is-active");
        });

        api.bind('closed', function () { 
            $("button.hamburger").removeClass("is-active");
        });

        var width = $( window ).width();

        // Close on resize
        $( window ).resize(function() {
            if ($( window ).width() !== width ) {
                nd_nav_mobile.close();
                width = $( window ).width();
            }
        });
    },

    open: function () {
        $("#mobile_nav").data( "mmenu" ).open();        
    },

    close: function () {
        $("#mobile_nav").data( "mmenu" ).close();        
    },

    toggle: function () {
        if($('#mobile_nav').hasClass('mm-opened')) {
            this.close();
        }
        else {
            this.open();
        }
    }

};
})(jQuery); 