(function($) {

//******************************************************************************
//* Projects carousel
//******************************************************************************
nd_project_carousel = {

    init: function () {
        $(document).ready(function(){
            $('.projects_carousel').on("init", function() {
                nd_project_carousel._init_slick_slide_buttons($(this));
            });

            $('.projects_carousel').slick({
                infinite: false,
                speed: 1000,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.projects_carousel').on("afterChange", function() {
                nd_project_carousel._init_slick_slide_buttons($(this));
            });
        });
    },

    _init_slick_slide_buttons: function($projects_carousel) {
        $projects_carousel.find('.slick-slide a').unbind();

        $projects_carousel.find('.slick-slide:not(.slick-active) a').click(function(e) {
            e.preventDefault();

            var $slick_slide = $(this).closest(".slick-slide");
            if ($slick_slide.prev().hasClass("slick-active")) {
                $projects_carousel.slick("slickNext");
            } else if ($slick_slide.next().hasClass("slick-active")) {
                $projects_carousel.slick("slickPrev");
            }
        });
    }
};

})(jQuery);
