(function($) {

//******************************************************************************
//* Helper for Visual Composer rendering
//******************************************************************************
nd_vc_render_helper = {

    init: function () {
        this._init_event();
    },

    // Call VC vc_js function to re-render all elements
    refresh_vc_render: function () {
    	vc_js();
    },

    _init_event: function () {
        this._refresh_vc_elements_on_safari();
    },

    // Refresh vc elements after 3 and 8 second on safari 
    _refresh_vc_elements_on_safari: function () {
        if ($('body').hasClass('nd_is_safari')) {
            if (! $('body').hasClass('nd_is_ios')) {
                
                setTimeout(function () {
                    nd_vc_render_helper.refresh_vc_render();
                }, 3000);

                setTimeout(function () {
                    nd_vc_render_helper.refresh_vc_render();
                }, 8000);

            }
        }
    }
};

})(jQuery); 