(function($) {
    
//******************************************************************************
//* Nd header gradient
//******************************************************************************

// TODO: this object needs refactoring
nd_header_gradient = {
    
    init: function () {
    	this._init_events();
        this._move_subtext();
        this._scroll_down_to_next_row();

        // we only need this with the big style
        if (nd_header_gradient._is_big_style()) {
            $(window).on('nd_resize_ready', function() {
                nd_header_gradient._move_subtext();
            });
        }
    },

    _is_big_style: function() {
        return $(".wpb_content_element.nd_header_gradient").hasClass("style_big");
    },

    _init_events: function () {

    	// Trigger event after image load
    	$('.nd_header_gradient').each(function() {
    		if ( $(this).find('img').length ) {
    			$(this).find('img').on('load', function() {
					$(window).trigger("nd_header_image_loaded");
				});
    		}
		});
    },

    _move_subtext: function () {
        var html = null;
        var $on_image_subtext_placeholder = $('[data-section="on_image"] [data-field="subtext_placeholder"]');
        var $below_image_subtext_placeholder = $('[data-section="below_img"] [data-field="subtext_placeholder"]');

        if ( window.innerWidth <= SCREEN_SIZE_SM_MAX || !nd_header_gradient._is_big_style()) {
            if ( $below_image_subtext_placeholder.length > 0 ) {
                if ( $below_image_subtext_placeholder.children().length < 1 ) {
                    html = $on_image_subtext_placeholder.html();
                    $on_image_subtext_placeholder.html('');
                    nd_header_gradient._save_subtext_below_image($below_image_subtext_placeholder, html);
                }
            }
        } else {
            if ( $on_image_subtext_placeholder.children().length < 1 ) {
                html = $below_image_subtext_placeholder.html();
                $below_image_subtext_placeholder.html('');
                nd_header_gradient._save_subtext_to_default_position($on_image_subtext_placeholder, html);
            }
        }
    },

    _save_subtext_to_default_position: function ($element, html) {
        $element.html(html);
    },

    _save_subtext_below_image: function ($element, html) {
        $element.html(html);
    },

    _scroll_down_to_next_row: function () {
        $('.nd_header_gradient .scroll_to_next_row').click(function() {

            // Get parent element
            $parent_elem = $(this).parents('[class^="nd-container-"]').last();

            if ($(this).parents('.fixed_row_container').first().parents('.nd-container-full').length) {
                $parent_elem = $(this).parents('.fixed_row_container').first().parents('.nd-container-full');
            }

            // Scroll to next vc row
            if ($parent_elem.nextAll('[class^="nd-container-"]').length) {

                $next_row = $parent_elem.nextAll('[class^="nd-container-"]').first();

                // Add selector class
                $next_row.addClass('scrolled_row');

                // Calculate offset
                var offset = $("nav.navbar").height();
                if (window.innerWidth > BREAKPOINT_NAV_MOBILE_MAX) {
                    offset = offset - 44; // As header height (100-44) 66 when scroll happen
                }

                // Scroll
                $.smoothScroll({ 
                    speed: 1000,               
                    scrollTarget: '.scrolled_row',
                    offset: -offset
                });

                // Remove selector class
                $next_row.removeClass('scrolled_row');
            }
        });
    },

};

})(jQuery);