(function($) {

/*
 * Add class, if element comes into viewport
 */
nd_add_class_on_viewport = {
    winHeight: $(window).height(),
    default_offset: 100,

    init: function () {

        // Recalc height of window in case of resize
        $(window).bind('resizeEnd', function() {
            nd_add_class_on_viewport.winHeight = $(window).height();
        });

        $(window).on('scroll', function() {
            nd_add_class_on_viewport.add_class_if_in_viewport();
        });
    },

    add_class_if_in_viewport: function () {

        // Rip through elements we're affecting
        $('.add_class_if_in_viewport:visible:not(.in_viewport)').each(function() {
            var viewport_offset = parseInt( $(this).attr("data-viewport-offset") );

            if (!viewport_offset) {
                viewport_offset = nd_add_class_on_viewport.default_offset;
            }

            // Get current scrollPos
            var trigger = $(window).scrollTop() + nd_add_class_on_viewport.winHeight - viewport_offset;
            var elementOffset = $(this).offset().top;

            if ( elementOffset < trigger ) {
                $(this).addClass('in_viewport');
            }
        });
    }
};



})(jQuery); // Fully reference jQuery after this point.