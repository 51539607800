(function($) {

EYE = {
  START_ROTATION_Y: -1.7,
  START_ROTATION_X: 0.1,
  PERCENT_OUT_OF_CENTER_X: 40,
  PERCENT_OUT_OF_CENTER_Y: 45,
  ROTATION_STRENGTH: 0.5,
  MAX_ROTATION_VALUE: 0.7,

  disable_whole_eye: false,
  scene: undefined,
  camera: undefined,
  renderer: undefined,
  eye_mesh: undefined,
  $relative_parent: undefined,
  screen_position_x: undefined,
  screen_position_y: undefined,
  screen_position_factor: undefined,

  init: function () {
      if (this.disable_whole_eye) { return; }

      // Kein Auge vorhanden
      if ($(".eye_container").length === 0) { 
        this.disable_whole_eye = true;
        return;
      }

      // Variable mit Elternelement setzen
      this.$relative_parent = $(".eye_container .flexible_image").parents(".wpb_content_element").eq(0);
      this.$image = $(".eye_container .flexible_image img");
      this.$canvas_container = $(".eye_container .flexible_image .canvas_container");

      if (this.$relative_parent.length === 0) {
        this.disable_whole_eye = true;
        return;
      }

      // Fallback hinzufügen      
      this.$relative_parent.find('[data-section="on_image"]').append("<img src='/app/themes/dieneudenker/assets/images/eye/fallback.png' class='eye_fallback'></img>");

      // Verfügbarkeit von WebGL überprüfen      
      if (!this._web_gl_available()) {
        this.disable_whole_eye = true;
        return;
      }

      this._init_scene();
      this._init_events();
      this._refresh_screen_position();

      $(window).on('nd_resize_ready', function() {
          EYE.resize();
      });
    },

  _init_scene: function () {
    
      this.$canvas_container.height(this.$image.height());
      // Scene initialisieren
      this.scene = new THREE.Scene(); 
      this.camera = new THREE.PerspectiveCamera( 35, this.$image.width() / this.$image.height(), 0.1, 1000 );

      this.renderer = new THREE.WebGLRenderer();       

      this.renderer.setSize( this.$image.width(), this.$image.height() ); 

      this.$canvas_container.get(0).appendChild( this.renderer.domElement );
      $(this.renderer.domElement).addClass("eye_canvas");

      // Umgebungslicht
      var light = new THREE.AmbientLight( 0x888888 );
      this.scene.add( light );

      // Spotlicht
      var spotLight = new THREE.SpotLight( 0x999999); 
      spotLight.position.set( -50, 50, 100 ); 
      spotLight.castShadow = true; 
      spotLight.shadowMapWidth = 1024; 
      spotLight.shadowMapHeight = 1024; 
      spotLight.shadowCameraNear = 500; 
      spotLight.shadowCameraFar = 4000; 
      spotLight.shadowCameraFov = 30; 
      this.scene.add( spotLight );

      // Augenobjekt
      var geometry   = new THREE.SphereGeometry(0.53, 32, 32);
      var texture = THREE.ImageUtils.loadTexture('/app/themes/dieneudenker/assets/images/eye/pupille.png');
      var bump_map = THREE.ImageUtils.loadTexture('/app/themes/dieneudenker/assets/images/eye/pupille_bump.png');
      var specular_map = THREE.ImageUtils.loadTexture('/app/themes/dieneudenker/assets/images/eye/pupille_specular.png');

      var material  = new THREE.MeshPhongMaterial({      
        map: texture,
        bumpMap: bump_map,
        bumpScale: 0.005,
        specularMap: specular_map,
        specular: new THREE.Color('#292929')
      });
      this.eye_mesh = new THREE.Mesh(geometry, material);
      this.scene.add(this.eye_mesh);

      this.eye_mesh.rotation.y = this.START_ROTATION_Y;
      this.eye_mesh.rotation.x = this.START_ROTATION_X;
      this.eye_mesh.position.y += 0.73;
      this.eye_mesh.position.x += 1.07;

      this.camera.position.z = 5;

      this._render();

    },

  _init_events: function () {
      $( "body" ).mousemove(function( event ) {
        EYE._rotate_to_mouse_position(event.pageX, event.pageY);
      });

    },

  _render: function () {
      requestAnimationFrame(EYE._render);
      EYE.renderer.render(EYE.scene, EYE.camera); 
    },

  resize:
    function () {
      if (this.disable_whole_eye) { return; }

      this._refresh_screen_position();
      this.$canvas_container.height(this.$image.height());

      this.camera.aspect = this.$image.width() / this.$image.height();
      this.camera.updateProjectionMatrix();

      this.renderer.setSize( this.$image.width(), this.$image.height() );

    },

  _refresh_screen_position: function () {
      
      this.screen_position_x = ( this.$image.width() / 2 ) + this.$image.offset().left;
      this.screen_position_x += ( this.$image.width() / 2 ) * (this.PERCENT_OUT_OF_CENTER_X / 100);

      this.screen_position_y = ( this.$image.height() / 2 ) + this.$image.offset().top;
      this.screen_position_y -= ( this.$image.height() / 2 ) * (this.PERCENT_OUT_OF_CENTER_Y / 100);

      this.screen_position_factor = this.ROTATION_STRENGTH / (window.innerHeight / 2); 
    },

  _rotate_to_mouse_position: function (mouse_x, mouse_y) {
      var diff_x = Math.abs(mouse_x - this.screen_position_x);
      var diff_y = Math.abs(mouse_y - this.screen_position_y);

      if (mouse_x < this.screen_position_x) {
        EYE.eye_mesh.rotation.y = this._limit_value(
                                                      this.START_ROTATION_Y - (diff_x * this.screen_position_factor), 
                                                      Math.abs(this.START_ROTATION_Y) + this.MAX_ROTATION_VALUE);
      }
      else {
        EYE.eye_mesh.rotation.y = this._limit_value(
                                                      this.START_ROTATION_Y + (diff_x * this.screen_position_factor), 
                                                      Math.abs(this.START_ROTATION_Y) + this.MAX_ROTATION_VALUE);
      }


      if (mouse_y < this.screen_position_y) {
        EYE.eye_mesh.rotation.x = this._limit_value(
                                                      this.START_ROTATION_X - (diff_y * this.screen_position_factor), 
                                                      Math.abs(this.START_ROTATION_X) + this.MAX_ROTATION_VALUE);
      }
      else {
        EYE.eye_mesh.rotation.x = this._limit_value(
                                                      this.START_ROTATION_X + (diff_y * this.screen_position_factor), 
                                                      Math.abs(this.START_ROTATION_X) + this.MAX_ROTATION_VALUE);
      }
    },

  _limit_value: function (value, absolute_limit) {
      if (value > absolute_limit) {
        return absolute_limit;
      }

      if (value < absolute_limit * -1 ) {
        return absolute_limit * -1;
      }

      return value;
    },

  _web_gl_available: function () {
      try {
          var canvas = document.createElement("canvas");
          return !!
              window.WebGLRenderingContext && 
              (canvas.getContext("webgl") || 
                  canvas.getContext("experimental-webgl"));
      } catch(e) { 
          return false;
      }       
    }
};

})(jQuery); 