(function($) {

/**
 * Price machine
 */
nd_price_machine = {

    concrete_price_machine: null,

    price_machine: "[data-section='price_machine']",

    init: function() {
        // get concrete price machine
        var type = $(this.price_machine).data("type");
        if (type === "video") {
            this.concrete_price_machine = nd_video_price_machine;
        } else {
            // no price machine of type found
            return;
        }

        this.concrete_price_machine.init();
        this._init_event_handlers();
    },

    _init_event_handlers: function() {
        // bottom bar positioning on scroll
        $(window).on('nd_scroll_thinned_out', function() {
            var offset_top = $(".bottom_bar_anchor").offset().top;
            var scroll_bottom = $(window).scrollTop() + $(window).height();
            var bottom_bar_height = $("[data-section='bottom_bar']").outerHeight();

            if(scroll_bottom >= offset_top + bottom_bar_height) {
                $("[data-section='bottom_bar']").addClass("in_place");
            } else {
                $("[data-section='bottom_bar']").removeClass("in_place");
            }
        });
    },

    refresh_price: function () {
        var total_price = this.concrete_price_machine.calculate_total_price();
        var discounted_price = null;

        var discount_percent = $(this.price_machine).attr("data-discount-percent");
        if (discount_percent) {
            discounted_price = total_price - total_price * discount_percent / 100;
        }

        $(this.price_machine)
            .find("[data-section='price_bar'] [data-field='total_price'] .price_holder")
            .html(this.get_formatted_price(total_price));

        if (discounted_price !== null) {
            $(this.price_machine)
                .find("[data-section='price_bar'] [data-field='total_price'] .discounted_price_holder")
                .html(this.get_formatted_price(discounted_price));
        }

        if (discounted_price !== null) {
            total_price = discounted_price;
        }

        $(this.price_machine)
            .find("[name=total_price]")
            // .val(total_price);
            .val(this.get_formatted_price(total_price));
    },

    get_formatted_price: function(price) {
        // var price_to_fixed;
        // if (Math.round(price) !== price) {
        //     price_to_fixed = price.toFixed(2);
        // } else {
        //     price_to_fixed = price;
        // }

        // return price_to_fixed.toLocaleString();
        var lang = document.documentElement.lang;
        if (typeof lang !== 'undefined') {
            return Math.floor(price).toLocaleString(lang);
        }
        return Math.floor(price).toLocaleString();
    }
 };

})(jQuery);
