(function($) {

nd_page_header_container = {
    init: function() {
        $(window).on('nd_resize_ready', function() {
            nd_page_header_container.set_embedded_video_size();
        });

        nd_page_header_container.set_embedded_video_size();
    },

    set_embedded_video_size: function() {
        $(".wpb_content_element.page_header_container .embedded_video").each(function() {
            // determine current navbar height
            var navbar_height = 66;
            if ($(window).width() <= SCREEN_SIZE_MD_MAX) {
                navbar_height = 55;
            }

            // clear width first
            $(this).width("");

            var page_header_height = $(window).height() - navbar_height;
            if ($(this).height() > page_header_height) {
                $(this).width(page_header_height * 16 / 9);
            }
        });
    }
};

})(jQuery);
