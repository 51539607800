(function($) {

/*
 * Background image
 */
nd_bg_image = {

    init: function () {
        this.add_class_if_background_is_loaded();
        
        $(window).on('nd_resize_ready', function() {
            nd_bg_image.add_class_if_background_is_loaded();
        });

        $(window).on('nd_scroll_thinned_out', function() {
            nd_bg_image.add_class_if_background_is_loaded();
        });
    },

    // Add class 'bg_image_loaded' when a background image is loaded
    add_class_if_background_is_loaded: function () {
        $('.bg_image_set div:visible').each(function() {
            var $bg_image_container = $(this);
            var image_src = $bg_image_container.css('background-image');
            image_src = image_src.replace('url(','').replace(')','').replace(/\"/gi, "");

            if (image_src !== 'none') {
                var img = new Image();
                img.src = image_src;

                img.onload = function() {
                    $(this).remove(); // prevent memory leaks as @benweet suggested
                    $bg_image_container.addClass('bg_image_loaded');
                };
            }
            
        });
    }
};



})(jQuery); // Fully reference jQuery after this point.