(function($) {

nd_youtube_iframe_api = {
    video_player: {},
    
    init: function () {        
        this._init_player();
        this._init_events();
    },

    init_api: function () {
        // Only load if there are video elements, because it has a big impact on page performance
        if ($(".nd_video").length > 0) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }        
    },

    refresh_status: function (event) {        
        // Show or hide overlay based on the current player state
        switch(event.target.getPlayerState()) {
            case -1: // Not started            
            case 5: // Positioned      
                nd_youtube_iframe_api._show_overlay(event.target);
                break;
            default:
                nd_youtube_iframe_api._hide_overlay(event.target);
                break;
        }
    },

    _init_player: function () {
        var id_number = 0;

        // Loop trough all video placeholders
        $(".nd_video [data-field='video_placeholder']").each(function() {

            // Generate ID and add to  element and parent
            var element_id = "nd_video_" + id_number.toString();
            $(this).attr("id", element_id);
            $(this).parents(".nd_video").attr("data-player-id", element_id);
            id_number++;            

            // Generate player
            var youtube_id = $(this).parents(".nd_video").attr("data-youtube-id");

            nd_youtube_iframe_api.video_player[element_id] = new YT.Player(element_id, {
                height: '100%',
                width: '100%',
                videoId: youtube_id,                
                playerVars: {
                    showinfo: 0,
                    origin: window.location.origin,
                    rel: 0,
                    color: "white"                    
                },
                events: {
                    'onStateChange': nd_youtube_iframe_api.refresh_status,
                    'onReady': nd_youtube_iframe_api.refresh_status
                },
                host: 'https://www.youtube-nocookie.com'
            });
        });
    },

    _init_events: function () {
        // Click on overlay (contains play button)
        $(".nd_video [data-section='overlay']").click(function () {
            var player = nd_youtube_iframe_api._get_player_from_element($(this));
            player.playVideo();
        });

        nd_youtube_iframe_api._resize_video_element();

        $(window).on('nd_resize_ready', function() {
            nd_youtube_iframe_api._resize_video_element();
        });
    },

    _hide_overlay: function (player) {
        $parent_elem = this._get_parent_element_from_player(player);
        $parent_elem.find("[data-section='overlay']").fadeOut();
    },

    _show_overlay: function (player) {
        $parent_elem = this._get_parent_element_from_player(player);
        $parent_elem.find("[data-section='overlay']").fadeIn(1000);
    },

    _resize_video_element: function () {
        $(".nd_video").each(function () {
            var max_height = nd_youtube_iframe_api._calculate_max_height($(this));
            var max_width = nd_youtube_iframe_api._calculate_max_width(max_height);
            var data_max_width = parseInt($(this).find("[data-section='wrapper']").attr('data-max-width'));
            
            var minimum_max_width = 600;
            var final_max_width = 0;

            if (data_max_width) {
                minimum_max_width = (minimum_max_width > data_max_width) ? data_max_width : minimum_max_width;
                
                if (max_width < data_max_width) {
                    if (max_width > minimum_max_width) {
                        final_max_width = max_width;
                    } else {
                        final_max_width = minimum_max_width; 
                    }
                } else {
                    final_max_width = data_max_width;
                }
            } else {
                if (max_width > minimum_max_width) {
                    final_max_width = max_width;
                } else {
                    final_max_width = minimum_max_width;
                }
            }

            $(this).find("[data-section='wrapper']").css('max-width', final_max_width);
        });
    },

    _calculate_max_width: function (max_height) {
        // Calculate max width based on max height
        var max_width = (max_height * 16 / 9);
        return max_width;
    },

    _calculate_max_height: function ($element) {
        // Video should never be higher than current screen height
        var max_height = window.innerHeight;
        var header_height = $('nav.navbar').innerHeight();
        // Info: delete empty_space_height -> it just makes no sense
        // var empty_space_height = $(".nd_empty_space").first().innerHeight();
        return max_height - (header_height);
    },

    // Gets a player object and returns it`s .nd_video parent
    _get_parent_element_from_player: function (player) {
        return $(player.getIframe()).parents(".nd_video");
    },

    // Gets a element inside .nd_video and returns the belonging player object
    _get_player_from_element: function ($element) {
        return this.video_player[$element.parents(".nd_video").attr("data-player-id")];
    }    
};

})(jQuery); 