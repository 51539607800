(function($) {

//******************************************************************************
//* Collapsible tabs container
//******************************************************************************

nd_collapsible_tabs_container = {
    current_breakpoint: null,

    current_open_tab_id: null,

    // the number of buttons in a row for each breakpoint.
    // TODO: these values could also be determined by querying
    // and translating the buttons' bootstrap col classes.
    // for now they are statically defined.
    BREAKPOINT_NUM_COLS_MAP: {
        "xs": 1,
        "sm": 2,
        "md": 3,
        "lg": 3
    },

    init: function () {
        // set the inital breakpoint
        nd_collapsible_tabs_container.current_breakpoint = nd_collapsible_tabs_container._get_current_breakpoint();
        // initial arrangement of columns
        nd_collapsible_tabs_container._rearrange_cols();

        this._init_event_handling();
    },

    _init_event_handling: function () {
        // resize handling
        // rearrange the columns if a breakpoint change has been detected.
        $(window).on('nd_resize_ready', function() {
            if (nd_collapsible_tabs_container._has_breakpoint_changed()) {
                nd_collapsible_tabs_container._rearrange_cols();
            }
        });
    },

    // hooking into collapse functionality.
    // register collapse callbacks for the current bodies.
    _register_collapse_callbacks: function() {
        $(".wpb_content_element.collapsible_tabs_container .body_column .collapse")
            // this is called once the collapse element is hidden completely after the transition
            .on("hidden.bs.collapse", function() {
                var id = $(this).attr("id");
                // detect if this tab was the recent open tab and set the
                // current_open_tab_id to null, to signal that all tabs
                // are closed now.
                if (id === nd_collapsible_tabs_container.current_open_tab_id) {
                    nd_collapsible_tabs_container.current_open_tab_id = null;
                }
            })
            // this is called once the collapse element's show transition starts
            .on("show.bs.collapse", function() {
                var id = $(this).attr("id");
                // hide all other tabs except this tab.
                // this way the accordion-like behaviour is realized.
                $(".wpb_content_element.collapsible_tabs_container .collapse").not(id).collapse("hide");

                // set the current open tab id
                nd_collapsible_tabs_container.current_open_tab_id = id;
            });
    },

    _get_current_num_cols: function() {
        return nd_collapsible_tabs_container.BREAKPOINT_NUM_COLS_MAP[nd_collapsible_tabs_container.current_breakpoint];
    },

    // this is the central method, that rearranges the columns depending on the current breakpoint
    _rearrange_cols: function() {
        var num_cols = nd_collapsible_tabs_container._get_current_num_cols();

        // remove all recent body columns
        $(".wpb_content_element.collapsible_tabs_container .body_column").remove();

        // add new empty body columns at the specific positions
        $(".wpb_content_element.collapsible_tabs_container .title_wrapper:nth-child(" + num_cols + "n),.title_wrapper:last-child")
            .after('<div class="col-sm-12 body_column"></div>');

        // copy content of hidden bodies into body columns
        $(".wpb_content_element.collapsible_tabs_container .body_column").each(function(index) {
            var start = index * num_cols;
            var end = start + num_cols;

            // join HTML of hidden bodies
            var bodies_html = $(".wpb_content_element.collapsible_tabs_container .hidden_body")
                .slice(start, end)
                .map(function() {
                    return $(this).html();
                })
                .get()
                // .join("");
                .join("\n");

            // insert bodies into body column
            $(this).append(bodies_html);
        });

        // set IDs of collapse elements using hidden elements
        $(".wpb_content_element.collapsible_tabs_container .body_column .collapse").each(function() {
            var id = $(this).find(".element_id").html().trim();
            $(this).attr("id", id);
        });

        // needed to reset the initial state of the buttons
        $(".wpb_content_element.collapsible_tabs_container .title_button").addClass("collapsed");

        nd_collapsible_tabs_container._register_collapse_callbacks();

        // if a tab is open before rearranging, it should also be open afterwards
        if (nd_collapsible_tabs_container.current_open_tab_id !== null) {
            $(".wpb_content_element.collapsible_tabs_container #" + nd_collapsible_tabs_container.current_open_tab_id)
                .collapse("show");
        }
    },

    _has_breakpoint_changed: function() {
        var current = nd_collapsible_tabs_container._get_current_breakpoint();
        if (current !== nd_collapsible_tabs_container.current_breakpoint) {
            nd_collapsible_tabs_container.current_breakpoint = current;
            return true;
        }
        return false;
    },

    _get_current_breakpoint: function() {
        if ($(window).width() <= SCREEN_SIZE_XS_MAX) {
            return "xs";
        }
        if ($(window).width() <= SCREEN_SIZE_SM_MAX) {
            return "sm";
        }
        if ($(window).width() <= SCREEN_SIZE_MD_MAX) {
            return "md";
        }
        else  {
            return "lg";
        }
    }
};

})(jQuery);
